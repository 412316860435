const apiUrl = process.env.REACT_APP_SHASHKA_API_URL;

// Admin signin flow
export const signIn = async (values) => {
  const res = await fetch(`${apiUrl}/users/login/`, {
    method: "POST",
    headers: {
      Accept: "application/json; version=1.0",
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
      'Access-Control-Allow-Headers': 'DNT,X-Mx-ReqToken,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type',
      "Content-Type": "application/json",
    },
    body: JSON.stringify(values),
  });
  const data = await res.json();
  return data;
};
export const verify = async (values) => {
  const res = await fetch(`https://shashka-taxi-shashka-backend-f873.twc1.net/api/users/taxopark/verify/`, {
    method: "PUT",
    headers: {
      Accept: "application/json; version=1.0",
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
      'Access-Control-Allow-Headers': 'DNT,X-Mx-ReqToken,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type',
      "Content-Type": "application/json",
    },
    body: JSON.stringify(values),
  });
  const data = await res.json();
  return data;
};
export const createTaxopark = async (values) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`https://shashka-taxi-shashka-backend-f873.twc1.net/api/users/taxopark/create/`, {
    method: "POST",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
    body: JSON.stringify(values),
  });
  const data = await res.json();
  return data;
};

export const createEmployee = async (values) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`https://shashka-taxi-shashka-backend-f873.twc1.net/api/users/taxopark/create-taxopark-employer/`, {
    method: "POST",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
    body: JSON.stringify(values),
  });
  const data = await res.json();
  return data;
};

// Users flow
export const getUsersList = async ({
  page = 1,
  ordering,
  filters = {},
  search = "",
}) => {
  const TOKEN = window.localStorage.getItem("token");
  const parameters = `?page=${page}${Object.keys(filters)
    .map((filter) => `&${filter}=${filters[filter]}`)
    .join("")}&ordering=${ordering || ""}&search=${search}`;
  const res = await fetch(`${apiUrl}/users/${parameters}`, {
    method: "GET",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
  });
  const data = await res.json();
  return data;
};

export const getUser = async (userId) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/users/${userId}/`, {
    method: "GET",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
  });
  return await res.json();
};

export const createUser = async (values) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/users/`, {
    method: "POST",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
    body: values,
  });
  const data = await res.json();
  return data;
};

export const blockUsers = async (ids, is_block, chat_id) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/users/block/`, {
    method: "POST",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
    body: JSON.stringify({
      users: ids,
      is_block,
      chat_id: chat_id,
    }),
  });
  const data = await res.json();
  return data;
};

export const updateUser = async (formData, userId) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/users/${userId}/`, {
    method: "PUT",
    headers: {
      Accept: "application/json; version=1.0",
      Authorization: `JWT ${TOKEN}`,
    },
    body: formData,
  });
  const data = await res.json();
  return data;
};

export const deleteUser = async (userId) => {
  const TOKEN = window.localStorage.getItem("token");
  await fetch(`${apiUrl}/users/${userId}/`, {
    method: "DELETE",
    headers: {
      Accept: "application/json; version=1.0",
      Authorization: `JWT ${TOKEN}`,
    },
  });
};

export const acceptDocument = async ({ userId, type }) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(
    `${apiUrl}/users/${userId}/documents/${type}/accept/`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json; version=1.0",
        "Content-Type": "application/json",
        Authorization: `JWT ${TOKEN}`,
      },
    }
  );
  const data = await res.json();
  return data;
};

export const rejectDocument = async ({ userId, type, reason }) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(
    `${apiUrl}/users/${userId}/documents/${type}/reject/`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json; version=1.0",
        "Content-Type": "application/json",
        Authorization: `JWT ${TOKEN}`,
      },
      body: JSON.stringify({ reason }),
    }
  );
  const data = await res.json();
  return data;
};

export const downloadDocument = async ({ userId, type, file }) => {
  const TOKEN = window.localStorage.getItem("token");
  const fileName = file.split('/').pop();
  const res = await fetch(
    `${apiUrl}/users/${userId}/documents/${type}/download/${fileName}`,
    {
      method: "GET",
      headers: {
        Authorization: `JWT ${TOKEN}`,
      },
    }
  );
  const blob = await res.blob();

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();

  return blob;
};

export const rejectorAcceptService = async (values) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/finance/driver/aggregators/moderation/`, {
    method: "POST",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
    body: JSON.stringify(values),
  });
  const data = await res.json();
  return data;
};

export const rejectorAcceptVehicle = async (values) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/finance/driver/vehicle/accepting/`, {
    method: "POST",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
    body: JSON.stringify(values),
  });
  const data = await res.json();
  return data;
};

export const changeUserComment = async (values) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/users/add/comment/`, {
    method: "POST",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
    body: JSON.stringify(values),
  });
  const data = await res.json();
  return data;
};

// Chats flow
export const getChat = async (id) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/chats/${id}/`, {
    method: "GET",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
  });
  const data = await res.json();
  return data;
};
export const getChatList = async ({
  page = 1,
  ordering,
  filters = {},
  search = "",
}) => {
  const TOKEN = window.localStorage.getItem("token");
  const parameters = `?page=${page}${Object.keys(filters)
    .map((filter) => `&${filter}=${filters[filter]}`)
    .join("")}&ordering=${ordering || ""}&search=${search}`;
  const res = await fetch(`${apiUrl}/chats/list/${parameters}`, {
    method: "GET",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
  });
  const data = await res.json();
  return data;
};
export const editChat = async (values, id) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/chats/edit/${id}/`, {
    method: "PUT",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
    body: JSON.stringify(values),
  });
  const data = await res.json();
  return data;
};

export const deleteChat = async (id) => {
  const TOKEN = window.localStorage.getItem("token");
  try {
    await fetch(`${apiUrl}/chats/delete/${id}/`, {
      method: "DELETE",
      headers: {
        Accept: "application/json; version=1.0",
        "Content-Type": "application/json",
        Authorization: `JWT ${TOKEN}`,
      },
    });
    return { success: true };
  } catch (error) {
    return { success: false, message: error };
  }
};
export const deleteChatMessage = async (message_ids) => {
  const TOKEN = window.localStorage.getItem("token");
  try {
    await fetch(`${apiUrl}/group-message/delete/`, {
      method: "DELETE",
      headers: {
        Accept: "application/json; version=1.0",
        "Content-Type": "application/json",
        Authorization: `JWT ${TOKEN}`,
      },
      body: JSON.stringify({
        message_ids,
      }),
    });
    return { success: true };
  } catch (error) {
    return { success: false, message: error };
  }
};
export const createChat = async (formData) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/chats/create/`, {
    method: "POST",
    headers: {
      Accept: "application/json; version=1.0",
      Authorization: `JWT ${TOKEN}`,
    },
    body: formData,
  });
  const data = await res.json();
  return data;
};

export const sentAudio = async (formData) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/chats/group/upload/audio/`, {
    method: "POST",
    headers: {
      Accept: "application/json; version=1.0",
      Authorization: `JWT ${TOKEN}`,
    },
    body: formData,
  });
  const data = await res.json();
  return data;
};

export const sentFile = async (formData) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/chats/group/upload/attachment/`, {
    method: "POST",
    headers: {
      Accept: "application/json; version=1.0",
      Authorization: `JWT ${TOKEN}`,
    },
    body: formData,
  });
  const data = await res.json();
  return data;
};

// Addresses flow
export const getAddress = async (locationId) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/locations/${locationId}`, {
    method: "GET",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
  });
  const data = await res.json();
  return data;
};

export const searchAddress = async (address) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(
    `https://back.shashka-taxi.com/api/search/?address=${address}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json; version=1.0",
        "Content-Type": "application/json",
        Authorization: `JWT ${TOKEN}`,
      },
    }
  );
  const data = await res.json();
  return data;
};

export const getAddressesList = async ({
  page = 1,
  ordering,
  filters = {},
  search = "",
}) => {
  const TOKEN = window.localStorage.getItem("token");
  const parameters = `?page=${page}${Object.keys(filters)
    .map((filter) => `&${filter}=${filters[filter]}`)
    .join("")}&ordering=${ordering || ""}&search=${search}`;
  const res = await fetch(`${apiUrl}/locations/${parameters}`, {
    method: "GET",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
  });
  const data = await res.json();
  return data;
};

export const createAddress = async (formData) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/locations/`, {
    method: "POST",
    headers: {
      Accept: "application/json; version=1.0",
      Authorization: `JWT ${TOKEN}`,
    },
    body: formData,
  });
  const data = await res.json();
  return data;
};

export const updateAddress = async (values, id) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/locations/${id}/`, {
    method: "PUT",
    headers: {
      Accept: "application/json; version=1.0",
      Authorization: `JWT ${TOKEN}`,
    },
    body: values,
  });
  const data = await res.json();
  return data;
};

export const deleteAddress = async (locationId) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/locations/${locationId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
  });
  return res;
};

export const deleteAddressComment = async (locationId, commentId) => {
  const TOKEN = window.localStorage.getItem("token");
  try {
    await fetch(`${apiUrl}/locations/${locationId}/comment/?id=${commentId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json; version=1.0",
        "Content-Type": "application/json",
        Authorization: `JWT ${TOKEN}`,
      },
    });
    return { success: true };
  } catch (error) {
    return { success: false };
  }
};

// Trade flow
export const getTradeTags = async () => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/marketplaces/tags/`, {
    method: "GET",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
  });
  const data = await res.json();
  return data;
};

export const getTradeCities = async () => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/marketplaces/cities/`, {
    method: "GET",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
  });
  const data = await res.json();
  return data;
};

export const getTradesList = async ({
  page = 1,
  ordering,
  filters = {},
  search = "",
  categories = [],
}) => {
  const TOKEN = window.localStorage.getItem("token");
  const parameters = `?page=${page}${Object.keys(filters)
    .map((filter) => `&${filter}=${filters[filter]}`)
    .join("")}&ordering=${
    ordering || ""
  }&search=${search}&tag=${categories.join(",")}`;
  const res = await fetch(`${apiUrl}/marketplaces/products/${parameters}`, {
    method: "GET",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
  });
  const data = await res.json();
  return data;
};

export const createTrade = async (formData) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/marketplaces/products/`, {
    method: "POST",
    headers: {
      Accept: "application/json; version=1.0",
      Authorization: `JWT ${TOKEN}`,
    },
    body: formData,
  });
  const data = await res.json();
  return data;
};

export const updateTrade = async (formData, id) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/marketplaces/products/${id}/`, {
    method: "PUT",
    headers: {
      Accept: "application/json; version=1.0",
      Authorization: `JWT ${TOKEN}`,
    },
    body: formData,
  });
  const data = await res.json();
  return data;
};

export const deleteTrade = async (tradeId) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/marketplaces/products/${tradeId}/`, {
    method: "DELETE",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
  });
  return res;
};

export const getTrade = async (tradeId) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/marketplaces/products/${tradeId}`, {
    method: "GET",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
  });
  const data = await res.json();
  return data;
};

// NEWS flow
export const getNews = async (newsId) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/news/${newsId}`, {
    method: "GET",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
  });
  const data = await res.json();
  return data;
};

export const getNewsTags = async () => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/news/tags/`, {
    method: "GET",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
  });
  const data = await res.json();
  return data;
};

export const getNewsList = async ({
  page = 1,
  ordering,
  filters = {},
  search = "",
  categories = [],
}) => {
  const TOKEN = window.localStorage.getItem("token");
  const parameters = `?page=${page}${Object.keys(filters)
    .map((filter) => `&${filter}=${filters[filter]}`)
    .join("")}&ordering=${
    ordering || ""
  }&search=${search}&tag=${categories.join(",")}`;
  const res = await fetch(`${apiUrl}/news/${parameters}`, {
    method: "GET",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
  });
  const data = await res.json();
  return data;
};

export const updateNews = async (formData, id) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/news/${id}/`, {
    method: "PUT",
    headers: {
      Accept: "application/json; version=1.0",
      Authorization: `JWT ${TOKEN}`,
    },
    body: formData,
  });
  const data = await res.json();
  return data;
};

export const createNews = async (formData) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/news/`, {
    method: "POST",
    headers: {
      Accept: "application/json; version=1.0",
      Authorization: `JWT ${TOKEN}`,
    },
    body: formData,
  });
  const data = await res.json();
  return data;
};

export const deleteNews = async (newsId) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/news/${newsId}/`, {
    method: "DELETE",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
  });
  return res;
};

export const createCategory = async (body, endpoint) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/${endpoint}/tags/`, {
    method: "POST",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
    body: JSON.stringify(body),
  });
  const data = await res.json();
  return data;
};

export const updateCategory = async (name, id, type) => {
    const TOKEN = window.localStorage.getItem("token");
    const res = await fetch(`${apiUrl}/${type}/tags/${id}/`, {
      method: "PUT",
      headers: {
        Accept: "application/json; version=1.0",
        "Content-Type": "application/json",
        Authorization: `JWT ${TOKEN}`,
      },
      body: JSON.stringify({name}),
    });
    const data = await res.json();
    return data;
}

// FAQ flow
export const getFAQ = async (faqsId) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/faqs/${faqsId}`, {
    method: "GET",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
  });
  const data = await res.json();
  return data;
};

export const getFAQList = async ({
  page = 1,
  ordering,
  filters = {},
  search = "",
  categories,
}) => {
  const TOKEN = window.localStorage.getItem("token");
  const parameters = `?page=${page}${Object.keys(filters).map(
    (filter) => `&${filter}=${filters[filter]}`
  )}&ordering=${ordering || ""}&search=${search}&categories=${categories.join(
    ","
  )}`;
  const res = await fetch(`${apiUrl}/faqs/${parameters}`, {
    method: "GET",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
  });
  const data = await res.json();
  return data;
};

export const getFAQTags = async () => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/faqs/tags/`, {
    method: "GET",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
  });
  const data = await res.json();
  return data;
};

export const updateFAQ = async ({ values, id }) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/faqs/${id}/`, {
    method: "PUT",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
    body: JSON.stringify(values),
  });
  const data = await res.json();
  return data;
};

export const createFAQ = async (values) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/faqs/`, {
    method: "POST",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
    body: JSON.stringify(values),
  });
  const data = await res.json();

  return data;
};

export const deleteFAQ = async (faqsId) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/faqs/${faqsId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
  });
  return res;
};

// Partners flow
export const getPartner = async (partnerId) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/partners/${partnerId}`, {
    method: "GET",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
  });
  const data = await res.json();
  return data;
};

export const getPartnersList = async ({
  page = 1,
  ordering,
  filters = {},
  search = "",
}) => {
  const TOKEN = window.localStorage.getItem("token");
  const parameters = `?page=${page}${Object.keys(filters).map(
    (filter) => `&${filter}=${filters[filter]}`
  )}&ordering=${ordering || ""}&search=${search}`;
  const res = await fetch(`${apiUrl}/partners/${parameters}`, {
    method: "GET",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
  });
  const data = await res.json();
  return data;
};

export const updatePartner = async (formData, id) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/partners/${id}/`, {
    method: "PUT",
    headers: {
      Accept: "application/json; version=1.0",
      Authorization: `JWT ${TOKEN}`,
    },
    body: formData,
  });
  const data = await res.json();
  return data;
};

export const createPartner = async (formData) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/partners/`, {
    method: "POST",
    headers: {
      Accept: "application/json; version=1.0",
      Authorization: `JWT ${TOKEN}`,
    },
    body: formData,
  });
  const data = await res.json();
  return data;
};

export const deletePartner = async (partnerId) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/partners/${partnerId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
  });
  return res;
};

// Cities flow

export const getCities = async () => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/finance/cities/`, {
    method: "POST",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
  });
  const data = await res.json();
  return data;
};

export const changePaymentDetails = async (id, body) => {
  const TOKEN = window.localStorage.getItem("token");
  const res = await fetch(`${apiUrl}/users/${id}/payment_information/`, {
    method: "PUT",
    headers: {
      Accept: "application/json; version=1.0",
      "Content-Type": "application/json",
      Authorization: `JWT ${TOKEN}`,
    },
    body: JSON.stringify(body),
  });
  const data = await res.json();
  return data;
};
